import React, { FC } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { createHttpLink } from '@apollo/client';
const uri = process.env.REACT_APP_DINGO_URL ?? 'https://dingo.yellowdog.software';

// export const uri = Object.freeze('http://localhost:4000');
// const uri = Object.freeze('https://dingo-dev.yellowdog.software');

const link = createHttpLink({
  uri,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export const DingoProvider: FC = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

const LOGIN_MUTATION = gql`
  mutation login($clientId: String!, $username: String!, $password: String!) {
    login(input: { clientId: $clientId, username: $username, password: $password }) {
      result {
        accessToken
        refreshToken
        expires
      }
    }
  }
`;

export function useDingoLogin(options?: MutationHookOptions) {
  return useMutation(LOGIN_MUTATION, { ...options, fetchPolicy: 'no-cache' });
}
